import barba from '@barba/core';
import barbaPrefetch from '@barba/prefetch';

import { gsap } from "gsap";
    
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { TextPlugin } from "gsap/TextPlugin";

/* The following plugins are Club GSAP perks */
import { SplitText } from "gsap/SplitText";

import Lenis from 'lenis';

import MouseFollower from "mouse-follower";
import {Reeller, ScrollerPlugin} from 'reeller';


gsap.registerPlugin(ScrollTrigger,TextPlugin,SplitText);

var lenis = null;
var offcanvasTl = null;
var pageTransitionTl = null;

let scrollPosY = [0];

Reeller.registerGSAP(gsap);
Reeller.use(ScrollerPlugin);

MouseFollower.registerGSAP(gsap);
window.cursor = null;

/*
 * Application
 */

document.addEventListener("DOMContentLoaded", function(event) {
    initPageTransitions();
    
    initSmoothScroll();
    initNavVisibility();
});



// ======================================== 
//  BARBA - PAGE TRANSITION
// ========================================

function initPageTransitions() {

    pageTransitionTl = gsap.timeline();

    barba.use(barbaPrefetch);

    barba.hooks.beforeLeave(() => {
        if (lenis != null) {  lenis.stop(); }
        if (cursor != null) { cursor.hide(); }
        if (pageTransitionTl != null) {pageTransitionTl.clear();}
    });

    barba.hooks.beforeEnter(() => {
        initParallax();
    });

    barba.hooks.enter((data) => {
        if(data.trigger !== "back") {
            scrollPosY.push(barba.history.current.scroll.y);
        }
    });

    barba.hooks.afterEnter((data) => {

        if (history.scrollRestoration) {
            history.scrollRestoration = 'manual';
        }

        if(data.trigger !== "back") {
            window.scrollTo(0,0);
        } else {
            window.scrollTo(0, scrollPosY.pop())
        }


        if (cursor == null) {
            initCursor();
            initCursorEvents();
        } else {
            initCursorEvents();
            cursor.show(); 
        }

        initOffcanvasNavbar();
        initSmoothScrollLinks();
        initMagneticItems();
        initMagneticItemsNav();
        initReels();
        initScrollTriggers();
        initVideoPlayer();
        initMagneticPortfolioItems();
        initMagneticHands();
        initMagneticDividers();
        initFormSlider();
        initAlertCloseBtn();


        var vids = document.querySelectorAll("video.onload-autoplay"); 
        
        vids.forEach(vid => { 
            var playPromise = vid.play(); 
            if (playPromise !== undefined) { 
                playPromise.then(_ => {}).catch(error => {}); 
            }; 
        });

        
        
        
    })

    barba.init({
        sync: false,
        debug: false,
        preventRunning: true,
        prevent: ({ el }) => el.classList && el.classList.contains('prevent-barba'),
        transitions: [
            {
                name: 'page-transition',
                once(data) {
                    pageEnter(data.next);
                },
                async leave(data) {
                    pageLeave(data.current.container);

                    await delay(500);
                    data.current.container.remove();
                },
                async enter(data) {
                    // INITS
                    pageEnter(data.next);
                }
            },
            {
                name: 'self',
                async leave(data) {
                    pageLeave(data.current.container);

                    await delay(500);
                    data.current.container.remove();
                },
                async enter(data) {
                    // INITS
                    pageEnter(data.next);
                }
            },

        ]
    });
}


function delay(n) {
    n = n || 2000;
    return new Promise((done) => {
        setTimeout(() => {
            done();
        }, n);
    });
}


function getContentWidth(element) {
    let widthWithPaddings = element.clientWidth;
    const elementComputedStyle = window.getComputedStyle(element, null);
    return (
      widthWithPaddings -
      parseFloat(elementComputedStyle.paddingLeft) -
      parseFloat(elementComputedStyle.paddingRight)
    );
  }









// ======================================== 
//  REELS
// ========================================

function initReels() {
    if (document.querySelector('.follow-me-headline') != null) {
        const reeller = new Reeller({
            container: '.follow-me-headline',
            wrapper: '.follow-me-headline-wrap',
            itemSelector: '.follow-me-headline-item',
            speed: 10,
            plugins: {
                scroller: {
                    speed: 1,
                    multiplier: 0.5,
                    threshold: 1,
                },
            }
        });
    }

}









// ======================================== 
//  HELPERS
// ========================================
function wrapWords(str, tmpl) {
    return str.replace(/[a-zA-Z'!,.]+/g, tmpl || "<span>$&</span>");
}

function splitSpans(query) {
    var spans = document.querySelectorAll(query);

    spans.forEach((span) => {        
        span.outerHTML = wrapWords(span.innerText, "<span class='italic'>$&</span>");
    });
}

function isTouchDevice() {   

    if (matchMedia('(pointer:fine)').matches) { 
        // hasMouse and therefore I don't consider it as touch device, even though it can have touch screen
        return false;
    } else {
        return ( 'ontouchstart' in window ) ||
            ( navigator.maxTouchPoints > 0 ) ||
            ( navigator.msMaxTouchPoints > 0 );
    }

};










// ======================================== 
//  FORM SLIDER
// ========================================

function initFormSlider() {
    let radios = document.querySelectorAll('.form-slider-point input');
    let sliderStatuses = document.querySelectorAll('.form-slider-status');

    radios.forEach((radio) => {
        radio.addEventListener('change', () => {
            // console.log(radio.getAttribute('value'));
            sliderStatuses.forEach((status) => {
                status.classList.remove('show');
            })

            document.querySelector('[data-status-for="' + radio.getAttribute('value') + '"]').classList.add('show');
        })
    })
}








// ======================================== 
//  CONTACT FORM SUCCESFUL FUNC
// ========================================

window.contactFormSuccesFunc = function() {
    var contactForm = document.querySelector('.contact-page-form form');

        contactForm.reset();

        showContactFormSuccessAlert();

        //successModal.show();
}


function initAlertCloseBtn() {
    let alertCloseBtn = document.querySelector('.alert-success .close-btn');
    
    if (alertCloseBtn) {
        alertCloseBtn.addEventListener('click', () => {
            hideContactFormSuccessAlert();
        });
    }
}







function showContactFormSuccessAlert() {
    let tl = gsap.timeline();

    tl.set('.alert-success .alert-bg', {
        translateX: '-100%'
    });
    
    tl.set('.alert-success .text', {
        translateY: '100%'
    });
    
    tl.set('.alert-success .close-btn', {
       translateY: '100%' 
    });

    tl.add(function(){
        document.querySelector('.form-alerts .alert-success').classList.remove('hidden');
    });

    tl.to('.alert-success .alert-bg', {
        translateX: '0',
        duration: 0.75,
        ease: "Power3.easeOut",
    });

    tl.to('.alert-success .text', {
        translateY: '0',
        duration: 0.5,
        ease: "Power3.easeOut",
    },'<0.375');
    
    tl.to('.alert-success .close-btn', {
        translateY: '0',
        duration: 0.5,
        ease: "Power3.easeOut",
    }, '<');

    tl.add(() => {
        setTimeout(() => {
            hideContactFormSuccessAlert();
        }, 3500);
    });

}

function hideContactFormSuccessAlert() {
    let tl = gsap.timeline();

    tl.set('.alert-success .close-btn', {
       translateY: '0' 
    });
    
    tl.set('.alert-success .text', {
        translateY: '0'
    });
    
    tl.set('.alert-success .alert-bg', {
        translateX: '0'
    });
    
    tl.to('.alert-success .close-btn', {
        translateY: '100%',
        duration: 0.5,
        ease: "Power3.easeIn",
    });
    
    tl.to('.alert-success .text', {
        translateY: '100%',
        duration: 0.5,
        ease: "Power3.easeIn",
    }, '<');
    
    tl.to('.alert-success .alert-bg', {
        translateX: '-100%',
        duration: 0.75,
        ease: "Power3.easeIn",
    },'<0.0875');
    
    tl.add(function(){
        document.querySelector('.form-alerts .alert-success').classList.add('hidden');
    });
}













// ======================================== 
//  SCROLL TRIGGERS
// ========================================

function initScrollTriggers() {
    
    // TEXT SCROLL TRIGGERS
    var triggerTexts = document.querySelectorAll('.trigger-text');

    splitSpans('.trigger-text .italic');

    triggerTexts.forEach((text) => {
        var split = new SplitText(text, {
            type: "lines",
            linesClass: "line"
        });

        split.lines.forEach((line) => {
            line.outerHTML = '<div class="line-wrap">' + line.outerHTML + '</div>';
        });
        
        var textTl = gsap.timeline({
            scrollTrigger: {
                trigger: text,
                toggleActions:'play none none none', 
                start: "0% 95%",
                end: "100% 0%"
            }
        });

        var lines = text.querySelectorAll('.line');

        textTl.from(lines, {
            y: "100%",
            stagger: .1,
            ease: "power3.out",
            duration: 1,
            delay: 0,
            onComplete: () => {
                split.revert();
            }
        });
    });



    // DIVIDER SCROLL TRIGGERS
    var dividers = document.querySelectorAll('.trigger-divider');

    dividers.forEach(divider => {
        var dividerTl = gsap.timeline({
            scrollTrigger: {
                trigger: divider,
                toggleActions:'play none none none', 
                start: "0% 95%",
                end: "100% 0%"
            }
        });

        dividerTl.fromTo(divider, {
            width: '0%',
        }, {
            width: '100%',
            duration: 2,
            ease: 'Power4.easeOut'
        })
    });



    // COLOR PALLETE
    var colorItems = document.querySelectorAll('.color'); 

    if (colorItems.length > 0) {
        gsap.set(colorItems, {
            clipPath: 'inset(0% 0% 100% 0% round 1rem)',
        });
    
        ScrollTrigger.batch(colorItems, {
            onEnter: (elements, triggers) => {
                gsap.to(elements, {
                    duration: 1,
                    clipPath: 'inset(0% 0% 0% 0% round 1rem)',
                    ease: 'Power3.easeOut',
                    stagger: 0.125,
                    ease: 'Power3.easeOut'
                })
            },
            once: true,
            start: "0% 90%"
        })
    }



    // SERVICES
    var serviceCards = document.querySelectorAll('.service-card'); 

    ScrollTrigger.batch(serviceCards, {
        onEnter: (batch) => {

            batch.forEach((card, index) => {
                let headline = card.querySelector('h4');
                let divider = card.querySelector('.divider');
                let content = card.querySelectorAll('.service-card-content p');
                

                let split = new SplitText([headline, content], {
                    type: 'lines',
                    linesClass: 'line'
                })

                split.lines.forEach((line) => {
                    line.outerHTML = '<div class="line-wrap">' + line.outerHTML + '</div>';
                });

                let tl = gsap.timeline();
                var lines = card.querySelectorAll('.line');

                tl.from(lines, {
                    y: "100%",
                    stagger: .075,
                    ease: "power3.out",
                    duration: 0.75,
                    delay: index * 0.15,
                    onComplete: () => {
                        split.revert();
                    }
                });
            });

        },
        once: true,
        start: "0% 100%"
    })
}





// ======================================== 
//  VIDEO PLAYER - TRIGGER & TIMELINE
// ========================================

function initVideoPlayer() {
    // TRIGGER
    var videos = document.querySelectorAll('.trigger-video');

    videos.forEach(video => {
        video.pause();
        let timeline = video.parentElement.querySelector('.video-timeline');
        let tlEvent = null;

        ScrollTrigger.create({
            trigger: video,
            start: "0% 95%",
            end: "100% 0%",
            onEnter: () => {
                video.muted = true;
                video.play();
                tlEvent = setInterval(() => {
                    timeline.style.width = (video.currentTime / video.duration) * 100 + '%';
                }, 20);
            },
            onEnterBack: () => {
                video.muted = true;
                video.play();
                tlEvent = setInterval(() => {
                    timeline.style.width = (video.currentTime / video.duration) * 100 + '%';
                }, 20);
            },
            onLeave: () => {
                video.pause();
                clearInterval(tlEvent);
            },
            onLeaveBack: () => {
                video.pause();
                clearInterval(tlEvent);
            },
        });        
    });


    // TIMELINE 
    // videos.forEach(video => {
    //     let timeline = video.parentElement.querySelector('.video-timeline');
        
    //     video.addEventListener('timeupdate', (e) => {
            
    //         timeline.style.width = (e.target.currentTime / e.target.duration) * 100 + '%';
    //     })
    // })
}







// ======================================== 
//  PARALLAX - DATA SPEED
// ========================================

function initParallax() {
    // apply parallax effect to any element with a data-speed attribute

    var wraps = document.querySelectorAll('.parallax-wrap');

    wraps.forEach(wrap => {
        var image = wrap.querySelector('.parallax-image');
        var alignCenterShift = (-1 * (image.getBoundingClientRect().height - wrap.getBoundingClientRect().height)) + 'px';

        gsap.set(image, {
            translateY: alignCenterShift
        })

        gsap.to(image, {
            y: (i, el) => {
                // paralaxovanie o presah veľkosti elementu v jeho containery
                return (el.getBoundingClientRect().height - el.parentElement.getBoundingClientRect().height);
            },
            ease: "none",
            scrollTrigger: {
                trigger: wrap,
                start: 'top bottom',
                end: 'bottom top',
                scrub: 0,
            }
        });
    });
}






// ======================================== 
//  MOUSE FOLLOWER
// ========================================

function initCursor() {
    if (cursor != null) {
        cursor.destroy();
    }

    cursor = new MouseFollower();
}

function initCursorEvents() {
    if (cursor == null) { return false; }

    //remove states
    cursor.removeText();
    cursor.removeImg();
    cursor.removeState('-zoom');
    cursor.removeState('-normal-blend');
    cursor.removeState('-pointer');
    cursor.removeState('-next');

    // init event listeners
    var thumbs = gsap.utils.toArray('.portfolio-item');
    thumbs.forEach((thumb) => {
        thumb.addEventListener('mouseenter', () => {
            cursor.setText('Explore');
        });

        thumb.addEventListener('mouseleave', () => {
            cursor.removeText();
        });
    });

    var nextProject = document.querySelector('.case-study-next');

    if (nextProject != null) { 
        nextProject.addEventListener('mouseenter', () => {
            cursor.addState('-next');
            cursor.setImg('/themes/kristianbalco/assets/images/icons/arrow_right_alt.svg');
        });
        nextProject.addEventListener('mouseleave', () => {
            cursor.removeState('-next');
            cursor.removeImg();
        })
    }

    var hoverZoomImages = document.querySelectorAll('.hover-zoom-img');

    hoverZoomImages.forEach(image => {
        if (image != null) {
            image.addEventListener('mouseenter', () => {
                cursor.addState('-zoom');
            });
            image.addEventListener('mouseleave', () => {
                cursor.removeState('-zoom');
            })
        }
    });
}

function initMagneticItems() {

    // BUTTONS
    const magneticItems = document.querySelectorAll('[data-magnetic]');

    if (!isTouchDevice()) {
        magneticItems.forEach(el => {
    
            const offsetX = el.dataset.magneticOffsetX ? el.dataset.magneticOffsetX : '60';
            const offsetY = el.dataset.magneticOffsetY ? el.dataset.magneticOffsetY : '40';
    
            el.addEventListener('mousemove', function(e) {
                const pos = this.getBoundingClientRect();
                const mx = e.clientX - pos.left - pos.width/2; 
                const my = e.clientY - pos.top - pos.height/2;
                const perX = mx / (pos.width/2);
                const perY = my / (pos.height/2);
            
                gsap.to(el, {
                    duration: 1,
                    transform: () => { 
                        let transform = '';
                        transform += 'scale(1) ';
                        transform += 'translate('+ perX * offsetX +'px, '+ perY * offsetY +'px) ';
                        transform += 'rotate3d('+ mx * -0.2 +', '+ my * -0.1 +', 0, 16deg)';
                        
                        return transform;
                    }
                });
    
                gsap.to(el.children[0], {
                    duration: 1,
                    transform: () => {
                        return 'translate('+ mx * 0.2 +'px, '+ my * 0.15 +'px)';
                    }
                });
            });
          
            el.addEventListener('mouseleave', function() {
                gsap.to(el, {
                    duration: 1,
                    ease: "elastic.out(1, 0.3)",
                    transform: 'scale(1) translate(0, 0) rotate3d(0, 0, 0, 0deg)'
                });
                gsap.to(el.children[0], {
                    duration: 1,
                    ease: "elastic.out(1, 0.3)",
                    transform: 'translate(0, 0)'
                });
            });
            
            el.addEventListener('mouseenter', () => cursor.hide());
            el.addEventListener('mouseleave', () => cursor.show());
        });
    }


    // CTA-BADGES
    const ctaBadges = document.querySelectorAll('.cta-badge');

    if (!isTouchDevice()) {
        ctaBadges.forEach(el => {
            const offsetX = el.dataset.magneticOffsetX ? el.dataset.magneticOffsetX : '40';
            const offsetY = el.dataset.magneticOffsetY ? el.dataset.magneticOffsetY : '40';
    
            el.addEventListener('mousemove', function(e) {
                const pos = this.getBoundingClientRect();
                const mx = e.clientX - pos.left - pos.width/2; 
                const my = e.clientY - pos.top - pos.height/2;
                const perX = mx / (pos.width/2);
                const perY = my / (pos.height/2);
                
                gsap.to(el, {
                    duration: 1,
                    transform: () => { 
                        let transform = '';
                        transform += 'scale(1) ';
                        transform += 'translate('+ perX * offsetX +'px, '+ perY * offsetY +'px) ';
                        transform += 'rotate3d('+ mx * -0.25 +', '+ my * -0.25 +', 0, 16deg)';
                        
                        return transform;
                    }
                });
    
                gsap.to(el.children[0], {
                    duration: 1,
                    transform: () => {
                        return 'scale(1.125)';
                    }
                });
    
                gsap.to(el.children[1], {
                    duration: 1,
                    transform: () => {
                        return 'translate('+ mx * 0.2 +'px, '+ my * 0.20 +'px)';
                    }
                });
            });
    
            el.addEventListener('mouseleave', function() {
                gsap.to(el, {
                    duration: 1,
                    ease: "elastic.out(1, 0.3)",
                    transform: 'scale(1) translate(0, 0) rotate3d(0, 0, 0, 0deg)'
                });
                gsap.to(el.children[0], {
                    duration: 1,
                    ease: "elastic.out(1, 0.3)",
                    transform: 'scale(1)'
                });
                gsap.to(el.children[1], {
                    duration: 1,
                    ease: "elastic.out(1, 0.3)",
                    transform: 'translate(0, 0)'
                });
            });
    
            el.addEventListener('mouseenter', () => cursor.hide());
            el.addEventListener('mouseleave', () => cursor.show());
        });
    }
}


function initMagneticItemsNav() {
    // BUTTONS
    const magneticNavItems = document.querySelectorAll('[data-magnetic-nav]');

    if (!isTouchDevice()) {
        magneticNavItems.forEach(el => {
    
            const offsetX = el.dataset.magneticOffsetX ? el.dataset.magneticOffsetX : '60';
            const offsetY = el.dataset.magneticOffsetY ? el.dataset.magneticOffsetY : '40';
            const magneticChildren = el.querySelectorAll('[data-magnetic-child]');
            
            el.addEventListener('mousemove', function(e) {
                const pos = this.getBoundingClientRect();
                const mx = e.clientX - pos.left - pos.width/2; 
                const my = e.clientY - pos.top - pos.height/2;
                const perX = mx / (pos.width/2);
                const perY = my / (pos.height/2);
    
            
                gsap.to(el, {
                    duration: 1,
                    transform: () => { 
                        let transform = '';
                        transform += 'scale(1) ';
                        transform += 'translate('+ perX * offsetX +'px, '+ perY * offsetY +'px) ';
                        transform += 'rotate3d('+ mx * -0.2 +', '+ my * -0.1 +', 0, 16deg)';
                        
                        return transform;
                    }
                });
    
                if (magneticChildren.length > 0) {
                    gsap.to(magneticChildren, {
                        duration: 1,
                        transform: () => {
                            return 'translate('+ mx * 0.2 +'px, '+ my * 0.15 +'px)';
                        }
                    });
                }
            });
          
            el.addEventListener('mouseleave', function() {
                gsap.to(el, {
                    duration: 1,
                    ease: "elastic.out(1, 0.3)",
                    transform: 'scale(1) translate(0, 0) rotate3d(0, 0, 0, 0deg)'
                });
    
                if (magneticChildren.length > 0) {
                    gsap.to(magneticChildren, {
                        duration: 1,
                        ease: "elastic.out(1, 0.3)",
                        transform: 'translate(0, 0)'
                    });
                }
            });
            
            el.addEventListener('mouseenter', () => cursor.hide());
            el.addEventListener('mouseleave', () => cursor.show());
        });
    }

    // NAV-TOGGLES
    const navToggles = document.querySelectorAll('.nav-toggle');

    if (!isTouchDevice()) {
        navToggles.forEach(el => {
            var wrap = el.querySelector('.mag-wrapper')
            var burger = el.querySelector('.burger');
            
            let xTo = gsap.quickTo(burger, "x", {duration: 1, ease: "elastic.out(1, 0.3)"});
            let yTo = gsap.quickTo(burger, "y", {duration: 1, ease: "elastic.out(1, 0.3)"});
    
    
            wrap.addEventListener('mouseenter', function(){
                cursor.setStick(el);
                cursor.addState('-zoom');
                cursor.setSkewing(2);
            });
    
            wrap.addEventListener('mousemove', function(e){
    
                let pos = burger.getBoundingClientRect();
                let mx = e.clientX - pos.left - pos.width/2; 
                let my = e.clientY - pos.top - pos.height/2;
                
                xTo(mx * 0.15);
                yTo(my * 0.15);
            });
    
            wrap.addEventListener('mouseleave', function(){
                cursor.removeStick();
                cursor.removeState('-zoom');
                cursor.removeSkewing();
    
                xTo(0);
                yTo(0);
            });
        });
    }    
}








// ======================================== 
//  MAGNETIC HANDS
// ========================================

function initMagneticHands() {
    const magneticHands = document.querySelectorAll('[data-magnetic-hand] img');

    if (!isTouchDevice()) {
        magneticHands.forEach(el => {
            const offsetX = el.dataset.magneticOffsetX ? el.dataset.magneticOffsetX : '40';
            const offsetY = el.dataset.magneticOffsetY ? el.dataset.magneticOffsetY : '40';
    
            el.addEventListener('mouseenter', function() {
                cursor.addState('-zoom');
                cursor.setSkewing(3);
            });
            
            el.addEventListener('mousemove', function(e) {
                const pos = this.getBoundingClientRect();
                const mx = e.clientX - pos.left - pos.width/2; 
                const my = e.clientY - pos.top - pos.height/2;
                const perX = mx / (pos.width/2);
                const perY = my / (pos.height/2);
            
                gsap.to(el, {
                    duration: 0.5,
                    transform: () => { 
                        let transform = '';
                        transform += 'translate('+ perX * offsetX +'px, '+ perY * offsetY +'px) ';
                        transform += 'rotate3d('+ mx * -1 +', '+ my * -1 +', 0, ' + Math.sqrt(Math.pow(perX, 2) + Math.pow(perY, 2 )) * 16 + 'deg)';
                        
                        return transform;
                    }
                });
            });
          
            el.addEventListener('mouseleave', function() {
                gsap.to(el, {
                    duration: 1,
                    ease: "elastic.out(1, 0.3)",
                    transform: 'translate(0, 0) rotate3d(0, 0, 0, 0deg)'
                });            
                cursor.removeState('-zoom');
                cursor.removeSkewing();
            });
        });
    }
}









// ======================================== 
//  MAGNETIC PORTFOLIO ITEMS
// ========================================

function initMagneticPortfolioItems() {    
    const portfolioItems = document.querySelectorAll('.portfolio-item');

    if (!isTouchDevice()) {
        portfolioItems.forEach(item => {
            item.addEventListener('mouseenter', () => {
                portfolioItems.forEach(el => {
                    if (item == el) {
                        el.classList.add('hover');
                    } else {
                        el.classList.add('no-hover');
                    }
                })
            });
    
            item.addEventListener('mouseleave', () => {
                portfolioItems.forEach(el => {
                    el.classList.remove('hover');
                    el.classList.remove('no-hover');
                })
            });
        })
    
        portfolioItems.forEach(el => {
            const offsetX = el.dataset.magneticOffsetX ? el.dataset.magneticOffsetX : '20';
            const offsetY = el.dataset.magneticOffsetY ? el.dataset.magneticOffsetY : '20';
    
            el.addEventListener('mouseenter', (e) => {
                const pos = e.target.getBoundingClientRect();
                const mx = e.clientX - pos.left - pos.width/2; 
                const my = e.clientY - pos.top - pos.height/2;
                const perX = mx / (pos.width/2);
                const perY = my / (pos.height/2);
    
                gsap.to(el.querySelector('.thumbnail'), {
                    duration: 0.5,
                    transform: () => { 
                        let transform = '';
                        transform += 'scale(1.1) ';
                        transform += 'translate('+ perX * offsetX +'px, '+ perY * offsetY +'px) ';
                        transform += 'rotate3d('+ mx * -1 +', '+ my * -1 +', 0, ' + Math.sqrt(Math.pow(perX, 2) + Math.pow(perY, 2 )) * 12 + 'deg)';
                        
                        return transform;
                    }
                });
    
                gsap.to(el.querySelector('.thumbnail img'), {
                    duration: 0.5,
                    transform: () => {
                        return 'scale(1.1) translate('+ perX * offsetX +'px, '+ perY * offsetY +'px)';
                    }
                });
            });
    
            el.addEventListener('mousemove', function(e) {
                const pos = this.getBoundingClientRect();
                const mx = e.clientX - pos.left - pos.width/2; 
                const my = e.clientY - pos.top - pos.height/2;
                const perX = mx / (pos.width/2);
                const perY = my / (pos.height/2);
            
                gsap.to(el.querySelector('.thumbnail'), {
                    duration: 0.5,
                    transform: () => { 
                        let transform = '';
                        transform += 'scale(1.1) ';
                        transform += 'translate('+ perX * offsetX +'px, '+ perY * offsetY +'px) ';
                        transform += 'rotate3d('+ mx * -1 +', '+ my * -1 +', 0, ' + Math.sqrt(Math.pow(perX, 2) + Math.pow(perY, 2 )) * 12 + 'deg)';
                        
                        return transform;
                    }
                });
                
                gsap.to(el.querySelector('.thumbnail img'), {
                    duration: 0.5,
                    
                    transform: () => {
                        return 'scale(1.1) translate('+ perX * offsetX +'px, '+ perY * offsetY +'px)';
                    }
                });
            });
          
            el.addEventListener('mouseleave', function() {
                gsap.to(el.querySelector('.thumbnail'), {
                    duration: 1,
                    ease: "power4.out",
                    transform: 'scale(1) translate(0, 0) rotate3d(0, 0, 0, 0deg)'
                });
    
                gsap.to(el.querySelector('.thumbnail img'), {
                    duration: 1,
                    ease: "power4.out",
                    transform: 'scale(1) translate(0, 0)'
                });
                
            });
        });
    }
}




// ======================================== 
//  MAGNETIC DIVIDERS
// ========================================


function initMagneticDividers() {
    const dividers = document.querySelectorAll('[data-magnetic-divider]');
    
    if (!isTouchDevice()) {
        dividers.forEach(divider => {
            // setting default divider with according to current viewport
            setDividerPath(divider, getContentWidth(document.querySelector('.container')));
    
            // update width on viewport size change
            window.addEventListener('resize', () => {
                setDividerPath(divider, getContentWidth(document.querySelector('.container')));
            });
    
            divider.addEventListener('mouseenter', () => {
                divider.time = Math.PI /2;
                divider.x = 0.5;
                divider.progress = 0;
                divider.prevScreenY = null;
    
                magneticDividerAnimateIn(divider);
                
            });
    
    
            divider.addEventListener('mousemove', (e) => {
                const { screenY } = e;
                let movementY = 0;
    
                if (divider.prevScreenY != null) {
                    movementY = screenY - divider.prevScreenY;
                }
                divider.prevScreenY = screenY;
    
                const box = e.target.getBoundingClientRect();
                
                divider.x = (e.clientX - box.left) / box.width;
                divider.progress += movementY;
    
                setDividerPath(divider, null, divider.x, divider.progress);
            });
    
            divider.addEventListener('mouseleave', () => {
                cancelAnimationFrame(divider.reqId)
                magneticDividerAnimateOut(divider);
            });
        })
    }
}

function setDividerPath(divider, width = null, x = 0.5, progress = 0) {
    if (width == null) { width = getContentWidth(document.querySelector('.container')) }
    
    let path = divider.querySelector('path');

    path.setAttribute('d', 'M 0,80 Q' + (width * x) + ',' + (80 + progress) + ' ' + width + ',80');
}

function magneticDividerAnimateIn(divider){
    if(divider.reqId){
        cancelAnimationFrame(divider.reqId);
        divider.time = Math.PI / 2;
    }
    setDividerPath(divider);
    // divider.reqId = requestAnimationFrame(() => {
    //     magneticDividerAnimateIn(divider);
    // });
}

function magneticDividerAnimateOut(divider){
    let newProgress = divider.progress * Math.sin(divider.time);
    
    setDividerPath(divider, null, divider.x, newProgress);
    divider.progress = lerp(divider.progress, 0, .06);
    divider.time += 0.6;
    
    if(Math.abs(divider.progress) > 0.5){
        divider.reqId = requestAnimationFrame(() => {
            magneticDividerAnimateOut(divider);
        });

    }
    //If the slope is almost flat, we stop the animation
    else {
        divider.time = Math.PI / 2;
        divider.progress = 0;
    }
}

function lerp(x, y, a) {
    return x * (1 - a) + y * a;
}















function pageLeave(current) {
    
    pageLeaveAnim();
  
}

function pageEnter(next) {

    // if there is opened navOffcanvas from previous page, close it first
    var navToggle = document.querySelector('.nav-toggle');
    if (navToggle.classList.contains('active')) {
        closeOffcanvarNavbar();
    } 

    pageEnterAnim();
}




function pageEnterAnim() {

    var heroDivider = document.querySelector('.hero-divider');
    var heroImage   = document.querySelector('.page-hero-image');
    var portfolioItems = document.querySelectorAll('.portfolio-item');
    var visiblePortfolioItems = [];

    
    //filter only portfolio items visible on load
    portfolioItems.forEach(item => {
        if (ScrollTrigger.isInViewport(item)){
            visiblePortfolioItems.push(item);
        }
    });
    
    var split = new SplitText(".headline-anim", {
        type: "words, lines",
        linesClass: "line"
    });
    
    document.querySelectorAll('.line').forEach((line) => {
        line.outerHTML = '<div class="line-wrap">' + line.outerHTML + '</div>';
    });

    pageTransitionTl.set(".loading-screen", { 
        translateY: 0
    }); 
    
    pageTransitionTl.set(".loading-screen .page-title", {
        translateY: 100,
    });

    if (heroImage) {
        pageTransitionTl.set('.page-hero-image', {
            clipPath: 'inset(100% 0% 0% 0% round 1rem)',
        });
    }

    pageTransitionTl.to(".loading-screen .page-title", {
        duration: 1,
        translateY: 0,
        ease: "Power3.easeOut",
    }, '<')

    pageTransitionTl.to(".loading-screen", {
        duration: 1,
        translateY: '-100%',
        ease: "Power3.easeInOut",
    },"<1");
    
    if (heroDivider) {
        pageTransitionTl.fromTo('.hero-divider', {
            width: '0%',
        }, {
            width: '100%',
            duration: 2,
            ease: 'Power4.easeOut'
        },'<');
    }
    
    if (heroImage) {
        pageTransitionTl.to('.page-hero-image', {
            duration: 1.75,
            // translateY: 600, 
            clipPath: 'inset(0% 0% 0% 0% round 1rem)',
            ease: 'Power3.easeOut',
        }, "<0.2");
        
        pageTransitionTl.from('.line', {
            duration: 1.45, 
            y: 200, 
            stagger: 0.1,
            ease: 'Power4.easeOut',
            onComplete: () => {
                split.revert();
            }
        }, "<");
    } else {
        pageTransitionTl.from('.line', {
            duration: 1.45, 
            y: 200, 
            stagger: 0.1,
            ease: 'Power4.easeOut',
            onComplete: () => {
                split.revert();
            }
        }, "<0.2");
    }
    

    pageTransitionTl.add(() => {
        if (lenis != null) { lenis.start(); }
    }, '<0.4');

    pageTransitionTl.set("html", { 
        cursor: "auto"
    },"<0.4");

    pageTransitionTl.set(".loading-screen", { 
        translateY: "100%"
    }); 
}

function pageLeaveAnim() {


    pageTransitionTl.set(".loading-screen", { 
        translateY: 0,
        clipPath: 'polygon(0% 100%, 100% 100%, 100% 100%, 0% 100%)'
    }); 

    pageTransitionTl.set(".loading-screen .page-title", {
        translateY: 100
    },'<');

    pageTransitionTl.set("html", { 
        cursor: "wait"
    });

    pageTransitionTl.to(".loading-screen", {
        duration: .5,
        clipPath: 'polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%)',
        ease: "Power3.easeIn",
    });
}
    
    






// ==============================
//  SMOOTH SCROLLING
// ==============================

function initSmoothScroll() {

    if (lenis == null) {
        lenis = new Lenis({
            lerp: 0.15
        })
    
        function raf(time) {
            lenis.raf(time);
            requestAnimationFrame(raf);
        }
    
        requestAnimationFrame(raf);

        lenis.stop();
    }
}

function initSmoothScrollLinks() {
    const anchorLinks = document.querySelectorAll('.anchor-link');
    
    anchorLinks.forEach((link) => {
        link.addEventListener('click', (e) => {
            e.preventDefault();
            var href = e.target.closest('.anchor-link').getAttribute('href');

            if (href != null) {
                lenis.scrollTo(href, {
                    offset: 0
                });
            }
        });
    });
}








// ======================================== 
//  NAVBAR - OFFCANVAS
// ========================================

function initOffcanvasNavbar() {
    offcanvasTl = gsap.timeline();

    const navToggles = document.querySelectorAll('.nav-toggle');
    const navToggleInputs = document.querySelectorAll('.nav-toggle input');

    navToggleInputs.forEach(input => {
        input.addEventListener('click', function(){
            
            if (navToggles[0].classList.contains('active')) {
                closeOffcanvarNavbar();
            } else {
                openOffcanvasNavbar();
            }
        });

    });

    window.addEventListener("keyup", (e => {
        if (e.key === "Escape") {
            closeOffcanvarNavbar();
        }
    }));

    const backdrop = document.querySelector('.backdrop-navbar');

    backdrop.addEventListener('click', function(){
        closeOffcanvarNavbar();
    });
}

function offcanvasNavbarEnter(tl) {
    
    var nav = document.querySelector('.nav-offcanvas');
    var navToggles = document.querySelectorAll('.nav-toggle');
    var navHeader = document.querySelector('.nav-offcanvas-header');
    var navFooter = document.querySelector('.nav-offcanvas-footer');
    var navItems = document.querySelectorAll('.nav-offcanvas .nav-offcanvas-item, .nav-offcanvas .cta-item a');

    var bgLayer01 = document.querySelector('.nav-offcanvas .bg-layer-01');

    var backdrop = document.querySelector('.backdrop-navbar');

    if (lenis != null) { lenis.stop(); }

    tl.set(navHeader, { autoAlpha: 0 });
    tl.set(navFooter, { autoAlpha: 0 });
    tl.set(backdrop, { autoAlpha: 0 });
    tl.set(nav, { 
        autoAlpha: 1,
    });

    tl.set(navItems, {
        autoAlpha: 0,
        translateX: 500
    });

    tl.set(nav, {
        clipPath: 'polygon(100% 0%, 100% 0%, 100% 100%, 100% 100%)'
    })
            
    tl.to(backdrop, { 
        duration: .65,
        autoAlpha: .8
    });


    tl.to(nav, {
        duration: .65,
        clipPath: 'polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%)',
        ease: 'power4.inOut',
    }, "<" );

    tl.to(bgLayer01, {
        duration: .65,
        scaleX: 1,
        ease: "power4.out",
    }, "<");

    tl.add(function(){
        navToggles.forEach(toggle => {
            toggle.classList.add('theme-dark');
        });
    }, '<');

    tl.to(navHeader, {
        duration: .65,
        autoAlpha: 1,
        delay: .05
    }, "<");

    tl.to(navFooter, {
        duration: .65,
        autoAlpha: 1,
    }, "<");

    tl.to(navItems, {
        duration: .65,
        autoAlpha: 1,
        translateX: 0,
        stagger: .025,
        ease: "elastic.out(1, 1)",
    }, "<0.25");

    
}

function offcanvasNavbarLeave(tl) {

    var nav = document.querySelector('.nav-offcanvas');
    var navToggles = document.querySelectorAll('.nav-toggle');
    var navHeader = document.querySelector('.nav-offcanvas-header');
    var navFooter = document.querySelector('.nav-offcanvas-footer');
    var navItems = document.querySelectorAll('.nav-offcanvas .nav-offcanvas-item, .nav-offcanvas .cta-item a');

    var bgLayer01 = document.querySelector('.nav-offcanvas .bg-layer-01');

    var backdrop = document.querySelector('.backdrop-navbar');

    if (lenis != null) { lenis.start(); }


    tl.set(navHeader, { autoAlpha: 1 });
    tl.set(navFooter, { autoAlpha: 1 });
    tl.set(navItems, { autoAlpha: 1 });

    tl.to(navHeader, {
        duration: .3,
        autoAlpha: 0,
    }, "<");

    tl.to(navFooter, {
        duration: .3,
        autoAlpha: 0,
    }, "<");

    tl.to(navItems, {
        duration: .65,
        autoAlpha: 0,
        translateX: 500,
        stagger: -0.0125,
        ease: "elastic.in(1.125, 1)",
    }, "<-0.25");
    

    tl.to(bgLayer01, {
        duration: .65,
        scaleX: 0,
        ease: 'power4.inOut',
    }, "<0.375");

    tl.to(nav, {
        duration: .65,
        clipPath: 'polygon(100% 0%, 100% 0%, 100% 100%, 100% 100%)',
        ease: 'power4.inOut',
        onComplete: function() {
            gsap.set(nav, {
                autoAlpha: 0
            });
        }
    }, "<" );
    
    
    tl.to(backdrop, { 
        duration: .3,
        autoAlpha: 0
    }, "<");
    
    tl.add(function(){
        navToggles.forEach(toggle => {
            toggle.classList.remove('theme-dark');
        });
    }, '<');
}

function openOffcanvasNavbar() {
    if (offcanvasTl != null) {
        const navToggles = document.querySelectorAll('.nav-toggle');
        const navToggleInputs = document.querySelectorAll('.nav-toggle input');
    
        navToggleInputs.forEach((input) => {
            input.checked = true;
        })
        navToggles.forEach(toggle => {
            toggle.classList.add('active');
        })
        
        offcanvasTl.clear();
        offcanvasNavbarEnter(offcanvasTl);
    }
}


function closeOffcanvarNavbar() {
    if (offcanvasTl != null) {
        const navToggles = document.querySelectorAll('.nav-toggle');
        const navToggleInputs = document.querySelectorAll('.nav-toggle input');

        navToggleInputs.forEach((input) => {
            input.checked = false;
        })
        navToggles.forEach(toggle => {
            toggle.classList.remove('active');
        })
        
        offcanvasTl.clear();
        offcanvasNavbarLeave(offcanvasTl);
    }
}








// ======================================== 
//  NAVBAR - REGULAR
// ========================================

function initNavVisibility() {

    var nav = document.querySelector('.navbar');
    var tl = gsap.timeline();

    var lastScrollTop = 0
    var threshold = 50;
 
    function startCheckScroll() {
        window.addEventListener('scroll', () => {
            var nowScrollTop = window.scrollY;
            
            // IF SCROLLS MORE THAN TRESHOLD
            if (Math.abs(lastScrollTop - nowScrollTop) >= threshold) {
                // SCROLL DOWN
                if (nowScrollTop > lastScrollTop) {
                    if (nav.classList.contains('opened')) {
                        nav.classList.remove('opened');
                        tl.clear();
                        navbarRegularHideAnim(tl);
                    }
                } 
                // SCROLL UP
                else {
                    if (!nav.classList.contains('opened')) {
                        nav.classList.add('opened');
                        tl.clear();
                        navbarRegularShowAnim(tl);
                    }
                }
                lastScrollTop = nowScrollTop;
            }
       });
    }
    startCheckScroll();

}


window.navbarRegularHideAnim = function(tl) {
    
    var logo = document.querySelectorAll('.nav-main .logo span');
    
    var items = document.querySelectorAll('.nav-main-center .nav-item');
    var contactBtn = document.querySelector('.nav-main-right .contact-me-btn');
    var navToggle = document.querySelector('.nav-main-right .nav-toggle');
    var navCenter = document.querySelector('.nav-main-center');

    tl.set(navToggle, {
        scale: 0
    });

    tl.add(() => {
        navCenter.classList.remove('overflow-visible');
    });

    tl.to(logo, {
        duration: 0.5,
        stagger: 0.05,
        translateY: '-100%',
        ease: 'Power4.easeOut'
    });
    
    tl.to(items, {
        duration: 0.5,
        stagger: 0.05,
        translateY: '-110%',
        ease: 'Power4.easeOut'
    }, '<0.1');

    tl.to(contactBtn, {
        duration: 1.5,
        scale: 0,
        autoAlpha: 0,
        ease: 'Power4.easeOut'
    }, '<');

    tl.to(navToggle, {
        duration: 0.75,
        autoAlpha: 1,
        scale: 1,
        ease: 'Power4.easeOut'
    }, '<0.1');
}


window.navbarRegularShowAnim = function(tl) {    
    var logo = document.querySelectorAll('.nav-main .logo span');

    var items = document.querySelectorAll('.nav-main-center .nav-item');
    var contactBtn = document.querySelector('.nav-main-right .contact-me-btn');
    var navToggle = document.querySelector('.nav-main-right .nav-toggle');
    var navCenter = document.querySelector('.nav-main-center');

    tl.to(navToggle, {
        duration: 0.75,
        autoAlpha: 0,
        scale: 0,
        ease: 'Power4.easeOut'
    }, '<');
    
    tl.to(contactBtn, {
        duration: 0.75,
        scale: 1,
        autoAlpha: 1,
        ease: 'Power4.easeOut'
    }, '<');
    
    tl.to(logo, {
        duration: 0.5,
        stagger: 0.05,
        translateY: '0',
        ease: 'Power4.easeOut'
    }, '<');


    tl.to(items, {
        duration: 0.5,
        stagger: 0.05,
        translateY: '0',
        ease: 'Power4.easeOut',
        onComplete: () => {
            navCenter.classList.add('overflow-visible');
        }
    }, '<0.2');
}